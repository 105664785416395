<template>
  <router-view v-if="showNavigationMenu" />
  <v-row
    v-else
    justify="space-between"
    class="relative"
    no-gutters>
    <v-col
      cols="2"
      style="top: 0; bottom: 0; left: 0"
      class="brand-gradient fixed"
      tag="aside">
        <div v-if="organizationLogoUrl" class="header-container mb-10" >
          <div class="header-container__before" >
          </div>
        </div>
        <div
          @click="selectPage(0)"
          class="mb-8 mb-lg-16 py-8 px-4 px-lg-8"
          :class="{ 'org-logo': !!organizationLogoUrl}"
          >
          <v-img
          max-height="100px"
          contain
          :src="organizationLogoUrl || require('@/assets/curved-headers/logo.png')" />
        </div>

      <v-list
        class="px-3 px-lg-6"
        color="transparent">
        <!-- home -->
        <v-list-item
          input-value="red"
          :ripple="false"
          role="link"
          class="mb-4 mb-lg-8 py-lg-1 pl-4 pl-lg-8 rounded-pill"
          :class="{ 'link-active': selectedPage == 0 }"
          @click="selectPage(0)">
          <div
            style="width: 25px; height: 25px; transform: translateY(-2px)"
            class="mr-3">
            <v-img :src="require('@/assets/navigation-icons/home.svg')"></v-img>
          </div>
          <span class="white--text text-uppercase">home</span>
        </v-list-item>
        <!-- explore -->
        <v-list-item
          input-value="yellow"
          :ripple="false"
          role="link"
          class="mb-4 mb-lg-8 py-lg-1 pl-4 pl-lg-8 rounded-pill"
          :class="{ 'link-active': selectedPage == 1 }"
          @click="selectPage(1)">
          <div
            style="width: 25px; height: 25px"
            class="mr-3">
            <v-img :src="require('@/assets/navigation-icons/search.svg')"></v-img>
          </div>
          <span
            class="white--text text-uppercase"
            style="transform: translateY(2px)"
            >explore</span
          >
        </v-list-item>
        <!-- profile -->
        <v-list-item
          input-value="purple"
          :ripple="false"
          role="link"
          class="rounded-pill py-lg-1 pl-4 pl-lg-8"
          :class="{ 'link-active': selectedPage == 2 }"
          @click="selectPage(2)">
          <div
            style="width: 20px; height: 20px; transform: translateY(-3px)"
            class="mr-3">
            <v-img :src="require('@/assets/navigation-icons/user.svg')"></v-img>
          </div>
          <span class="white--text text-uppercase">profile</span>
        </v-list-item>
        <!-- settings -->
        <v-list-item
          :to="{ name: 'Settings' }"
          class="settings absolute rounded-pill py-lg-1 pl-4"
          :ripple="false"
          style="120px; transform: translateX(-13px);"
          :class="{ 'link-active': selectedPage == 3 }"
          @click="selectPage(3)">
          <div style="width: 52px; height: 52px">
            <v-img :src="require('@/assets/profile/settings.svg')"></v-img>
          </div>
          <span
            class="white--text text-uppercase"
            :ripple="false"
            style="transform: translateY(2px)"
            >settings</span
          >
        </v-list-item>
        <!-- log-out -->
        <v-list-item
          :to="{ name: 'Logout' }"
          class="logout absolute w-full py-lg-1 pl-4"
          style="bottom: 60px; transform: translateX(-12px)">
          <div style="width: 52px; height: 52px">
            <v-img :src="require('@/assets/auth/log-out.svg')"></v-img>
          </div>
          <span
            class="white--text text-uppercase"
            style="transform: translateY(2px)"
            >log out</span
          >
        </v-list-item>
      </v-list>
    </v-col>

    <v-col
      cols="10"
      style="margin-left: 16.66%"
      class="pa-0"
      tag="main">
      <router-view class="view-container" />
    </v-col>
  </v-row>
</template>

<script>
  import ProtectedRoutesViewMixin from '@/layouts/protected-routes-view/ProtectedRoutesView.mixin.vue';
  export default {
    mixins: [ProtectedRoutesViewMixin],
    methods: {
      navigateToRoute(name) {
        this.$router.push({
          name,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
.header-container{
  position: relative;
    overflow: hidden !important;
    height: 19vh;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    $this: &;
    &__before {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 200%;
      width: 260%;
      background-color: #FFFFFF;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  .org-logo {
    position: absolute;
    top: 0;
    width: 100%;
    object-fit: contain;
  }
  ::v-deep .v-list-item--link:before {
    background-color: transparent;
    border-radius: 9999px;
  }
  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 17.07px;
  }
  .logout,
  .settings {
    right: 2px;
    left: 26px;
  }
  .settings {
    bottom: 130px;
  }
  .link-active {
    background-color: rgba(255, 255, 255, 0.32);
  }
  @media screen and (min-width: 1264px) {
    .logout,
    .settings {
      left: 36px;
    }
    .settings {
      bottom: 150px;
    }
  }
</style>
