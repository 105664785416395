<script>
  import { mapGetters } from 'vuex';

  export default {
    data() {
      return {
        // move to store
        selectedPage: 0,
      };
    },
    computed: {
      ...mapGetters('OrganizationsModule', ['getOrganizationLogoUrl']),
      showMobileTabBar() {
        return this.$route.meta?.showMobileTabBar;
      },
      showNavigationMenu() {
        return this.$route.name === 'Tutorial' || this.$route.name === 'ChooseCategory';
      },
      organizationLogoUrl() {
        return this.getOrganizationLogoUrl;
      },
    },
    created() {
      this.setCurrentPageOnLoad();
    },
    methods: {
      setCurrentPageOnLoad() {
        const routeName = this.$route.name;

        switch (routeName) {
          case 'Home':
            this.selectedPage = 0;
            break;
          case 'Explore':
            this.selectedPage = 1;
            break;
          case 'Profile':
            this.selectedPage = 2;
            break;
          case 'Settings':
            this.selectedPage = 3;
            break;
        }
      },
      selectPage(pageNumber) {
        this.selectedPage = pageNumber;
        switch (pageNumber) {
          case 0:
            this.$router.push(
              {
                name: 'Home',
              },
              () => {}
            );
            break;
          case 1:
            this.$router.push(
              {
                name: 'Explore',
              },
              () => {}
            );
            break;
          case 2:
            this.$router.push(
              {
                name: 'Profile',
              },
              () => {}
            );
            break;
          case 3:
            this.$router.push(
              {
                name: 'Settings',
              },
              () => {}
            );
            break;
          default:
            break;
        }
      },
    },
  };
</script>
